import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Grid2, Snackbar, Button } from '@mui/material';

import {
    AppBar,
    ClaimModal,
    FooterView,
    FormView,
    MainView,
    QuotationView,
    TransactionView,
    PlaneView
} from './components';

import { AppContext, TransactionService } from './services';

import Banner from '../src/assets/images/BG.png';

import './spa.css';

const StyledGridContainer = styled(Grid2)`
    min-height: 100vh;
    min-width: 100vw;
    background: #013462;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
        border: 2px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    /* Custom scrollbar for Firefox */
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;

    /* Custom scrollbar for Edge/IE */
    -ms-overflow-style: none; /* Hide default scrollbar */
`;

const StyledQuotationContainer = styled(Grid2)`
    background: #fff;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url(${Banner});
    background-size: cover;
    background-position: center;

    @media (min-width: 300px) and (max-width: 767px) {
        background-position: 38%;
        height: 70vh;
    }
`;

const Overlay = styled.div`
    position: absolute;
    top: 20%;
    left: 30%;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #013462;
    text-align: center;

    @media (min-width: 300px) and (max-width: 767px) {
        top: 30%;
        left: 50%;
    }
`;

const Heading = styled.h1`
    font-size: 54px;
    font-weight: 790;
    color: #2146ad;
    margin: 0;
    font-family: 'Myriad Pro', sans-serif !important;

    @media (min-width: 300px) and (max-width: 767px) {
        font-size: 20px;
    }
`;

const SubHeading = styled.h1`
    font-size: 54px;
    color: #ff6002;
    padding-right: 52px;
    font-family: 'Myriad Pro', sans-serif !important;
    margin: -20px;
    font-weight: 790;

    @media (min-width: 300px) and (max-width: 767px) {
        font-size: 20px;
        margin: -6px;
        padding-right: 20px;
    }
`;

const StyledButton = styled(Button)`
    font-size: 20px !important;
    background-color: #ff6002 !important;
    width: 256px;
    color: white !important;
    border-radius: 28px !important;
    padding: 6px !important;
    margin-right: 144px !important;
    margin-top: 20px !important;

    @media (min-width: 300px) and (max-width: 768px) {
        margin-right: 8px !important;
        font-size: 12px !important;
        width: 150px;
        padding: 3px !important;
        margin-top: 10px !important;
    }
`;

function App() {
    const { config, setConfig } = React.useContext(AppContext);
    const urlParams = new URLSearchParams(window.location.search);
    const stage = urlParams.get('stage');

    React.useEffect(() => {
        if (stage) {
            if (stage === 'S') {
                setConfig((c) => ({ ...c, page: 'transaction', applicationStatus: 'WP' }));
            } else if (stage === 'F') {
                setConfig((c) => ({ ...c, page: 'transaction', applicationStatus: 'F' }));
            } else {
                handleTransactionStatus(stage, () =>
                    handleTransactionStatus(stage, () => {
                        handleTransactionStatus(stage, () =>
                            handleTransactionStatus(stage, () =>
                                handleTransactionStatus(stage, () => {
                                    setTimeout(() => window.location.reload(), 5000);
                                })
                            )
                        );
                    })
                );
            }
        }
    }, [stage]);

    const handleTransactionStatus = async (tranxRef, callback = () => {}) => {
        try {
            const res = await TransactionService(tranxRef);
            const status = res?.policyStatus || '';

            if (['s', 'f'].includes(status)) window.location.href = `/?stage=${status.toUpperCase()}`;
            if (!callback) return;
            callback();
        } catch (error) {
            console.error('API handleTransactionStatus Call error', error);
        }
    };

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <StyledGridContainer container direction="column">
            <Grid2 size={{ xs: 12 }}>
                <AppBar
                    handleShowClaimModal={() => setConfig((c) => ({ ...c, modal: 'file-claim' }))}
                    refWindow={window}
                />
                {/* <img src={require('./assets/images/BG.png')} style={{ width: '100%', height: 'auto' }} /> */}
                <Container>
                    <Overlay>
                        <Heading>SAFE TRAVELS</Heading>
                        <SubHeading>START HERE.</SubHeading>
                        <StyledButton variant="contained" onClick={() => scrollToElement('get-started-section')}>
                            LET’S GET STARTED
                        </StyledButton>
                    </Overlay>
                </Container>
            </Grid2>
            <Grid2
                size={{ xs: 12 }}
                sx={{
                    background: '#002444',
                    display: { xs: 'none', sm: 'flex' },
                    justifyContent: ' center',
                    alignItems: ' center',
                    padding: '20px 0',
                    marginTop: '-10px'
                }}
            >
                <PlaneView />
            </Grid2>

            {(config.page === 'landing' && (
                <Grid2 size={{ xs: 12 }} id="get-started-section">
                    <MainView />
                </Grid2>
            )) ||
                null}
            {(config.page === 'landing' && (
                <StyledQuotationContainer size={{ xs: 12 }}>
                    <QuotationView />
                </StyledQuotationContainer>
            )) ||
                null}

            {(config.page === 'form' && (
                <Grid2 size={{ xs: 12 }}>
                    <FormView />
                </Grid2>
            )) ||
                null}

            {config.page === 'transaction' && (
                <Grid2 size={{ xs: 12 }}>
                    <TransactionView />
                </Grid2>
            )}

            <Grid2 size={{ xs: 12 }} sx={{ background: 'white' }}>
                <FooterView />
            </Grid2>

            {config?.modal === 'file-claim' && (
                <ClaimModal handleClose={() => setConfig((c) => ({ ...c, modal: '' }))} />
            )}

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={stage && !['S', 'F'].includes(stage)}
                onClose={() => {}}
                message="Processing Transaction ..."
                key={'bottom' + 'right'}
            />
        </StyledGridContainer>
    );
}

export default App;
