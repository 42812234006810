import React from 'react';
import { Grid2, TextField, Typography, styled, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import dayjs from 'dayjs';

import { ButtonComponent, DaterangeComponent, InputComponent, SelectComponent } from '../..';
import { PlanModal, TravellersModal } from '../';

import { AppContext, DurationService, QuotationService } from '../../../services';

const StyledText = styled(Typography)`
    font-size: 10px !important;
    margin-bottom: -12px !important;
    font-weight: 600 !important;
`;

const mappings = {
    F: 'FAMILY',
    G: 'GROUP',
    I: 'INDIVIDUAL',
    WC: 'WITH_COMPANION'
};

function SelectionScaffold() {
    const { maintenance, setState, state } = React.useContext(AppContext);
    const [openModal, setOpenModal] = React.useState('');
    const [companionsAllowed, setCompanionsAllowed] = React.useState(false);

    React.useEffect(() => {
        if (state?.groupSize) {
            if (state?.groupSize === 'I') {
                setCompanionsAllowed(true);
            } else {
                const { ADULT = 0, MINOR = 0 } = state;
                setCompanionsAllowed(!!(ADULT + MINOR >= 1));
            }
        } else {
            setCompanionsAllowed(false);
        }
    }, [state.groupSize, state.ADULT, state.MINOR]);

    React.useEffect(() => {
        if (state.geoCoverage === 'D') {
            setState((s) => ({
                ...s,
                country: 'Philippines',
                destination: 23
            }));
        } else if (state.geoCoverage === 'IWD') {
            setState((s) => ({
                ...s,
                country: 'World Wide',
                destination: 194
            }));
        } else {
            setState((s) => ({
                ...s,
                country: '',
                destination: ''
            }));
        }
    }, [state.geoCoverage]);

    const handleOnChanges = (target, value) => {
        setState((p) => ({ ...p, [target]: value }));
    };

    function validateDates() {
        if (!state?.startDate || !state?.endDate) return false;
        if (moment(moment(new Date(state?.startDate)).startOf('day')).isBefore(new Date(moment().startOf('day'))))
            return false;
        if (
            moment(moment(new Date(state?.endDate)).startOf('day')).isBefore(
                new Date(moment(new Date(state?.startDate)).startOf('day'))
            )
        )
            return false;

        return true;
    }

    return (
        <Grid2 container columnSpacing={6} rowSpacing={3}>
            <Grid2 size={{ xs: 12 }}>
                <InputComponent
                    value={state?.companyName ?? ''}
                    onChange={(e) => {
                        const {
                            target: { value }
                        } = e;
                        const { label: companyName = '', value: company = '' } = value;
                        setState((s) => ({
                            ...s,
                            company,
                            companyName: typeof value === 'string' ? value : companyName
                        }));
                    }}
                    textAlign="center"
                    selections={maintenance?.company ?? []}
                    selector="label"
                    placeholder="Choose Company"
                    onBlur={() => {
                        if (!state?.company) {
                            setState((s) => ({
                                ...s,
                                companyName: ''
                            }));
                        }
                    }}
                />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
                <SelectComponent
                    disabled={!state.type}
                    value={state.groupSize}
                    handleOnChanges={(val) => {
                        handleOnChanges('groupSize', val);
                        if (val === 'G') {
                            handleOnChanges('ADULT', 0);
                            handleOnChanges('MINOR', 0);
                            handleOnChanges('totalCompanions', ['ADULT']);
                        } else {
                            handleOnChanges('ADULT', 0);
                            handleOnChanges('MINOR', 0);
                            handleOnChanges('totalCompanions', []);
                        }
                    }}
                    placeholder="Travel Group Size"
                    selections={
                        state?.type
                            ? (maintenance?.travelGroupSize ?? [])
                                  .filter((t) => t?.travelTypeCd === state.type)
                                  .map((t) => ({
                                      label: t.travelGroupSizeDesc,
                                      value: t.travelGroupSizeCd
                                  }))
                            : []
                    }
                />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
                <SelectComponent
                    disabled={!state.type || !state.groupSize}
                    handleOnClick={() => setOpenModal('plan')}
                    placeholder={
                        (maintenance?.travelPlan || []).find((f) => f.travelPlanCd === state.plan)?.travelPlanDesc ||
                        'Choose Plan'
                    }
                />
            </Grid2>

            {state?.geoCoverage && (
                <Grid2 size={{ xs: 12 }}>
                    <InputComponent
                        disabled
                        value={
                            (
                                (maintenance?.travelGeoCoverage || []).find(
                                    (f) => f.travelGeoCoverageCd === state.geoCoverage
                                ) || {}
                            ).travelGeoCoverageDesc
                        }
                    />
                </Grid2>
            )}

            {state?.transportMode && (
                <Grid2 size={{ xs: 12 }}>
                    <InputComponent
                        disabled
                        value={
                            (
                                (maintenance?.travelTransportMode || []).find(
                                    (f) => f.travelTransportModeCd === state.transportMode
                                ) || {}
                            ).travelTransportModeDesc
                        }
                    />
                </Grid2>
            )}

            {state?.maxTripDuration && state?.durationTypeCd && (
                <Grid2 size={{ xs: 12 }}>
                    <InputComponent
                        disabled
                        value={
                            (
                                (maintenance?.travelDurationSelection || []).find(
                                    (f) =>
                                        f.durationTypeCd === state.durationTypeCd &&
                                        f.durationSelectionCd === state.maxTripDuration
                                ) || {}
                            ).durationSelectionDesc
                        }
                    />
                </Grid2>
            )}

            {state?.plan && (
                <Grid2 size={{ xs: 12 }}>
                    <InputComponent
                        value={state?.country ?? ''}
                        onChange={(e) => {
                            const {
                                target: { value }
                            } = e;
                            const { label: country = '', value: destination = '' } = value;
                            setState((s) => ({
                                ...s,
                                destination,
                                country: typeof value === 'string' ? value : country
                            }));
                        }}
                        textAlign="center"
                        selections={maintenance?.country ?? []}
                        selector="label"
                        placeholder="Destination"
                        onBlur={() => {
                            if (!state?.destination && state?.geoCoverage === 'D') {
                                setState((s) => ({
                                    ...s,
                                    country: 'Philippines',
                                    destination: 23
                                }));
                            } else if (!state?.destination && state?.geoCoverage === 'IWD') {
                                setState((s) => ({
                                    ...s,
                                    country: 'WorldWide',
                                    destination: 194
                                }));
                            } 
                        }}
                        disabled={state?.geoCoverage === 'D' || state?.geoCoverage === 'IWD'}
                    />
                </Grid2>
            )}

            {!!(maintenance?.parameters || []).filter((f) =>
                [
                    `GROUP_SIZE.${mappings[state?.groupSize] || 69}.MINOR.LIMIT`,
                    `GROUP_SIZE.${mappings[state?.groupSize] || 69}.ADULT.LIMIT`
                ].includes(f.paramName)
            ).length && (
                <Grid2 size={{ xs: 12 }}>
                    <SelectComponent
                        disabled={!state.groupSize}
                        handleOnClick={() => setOpenModal('travellers')}
                        placeholder={(function () {
                            const { ADULT, MINOR } = state;

                            if (ADULT && !MINOR) {
                                return `ADULT: ${ADULT}`;
                            } else if (!ADULT && MINOR) {
                                return `MINOR: ${MINOR}`;
                            } else if (ADULT && MINOR) {
                                return `ADULT: ${ADULT}, MINOR: ${MINOR}`;
                            } else {
                                return 'Additional Traveler/s';
                            }
                        })()}
                    />
                </Grid2>
            )}

            {state?.plan && state.plan !== 'A' && (
                <>
                    <Grid2 size={{ xs: 12 }}>
                        <StyledText variant="h6">Travel Duration</StyledText>
                    </Grid2>

                    <Grid2 size={{ xs: 12 }}>
                        <>
                            <Grid2 container spacing={1}>
                                <Grid2 size={{ xs: 12, md: 6 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box sx={{ width: '100%' }}>
                                            <DatePicker
                                                label="Travel Start Date"
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                        fontSize: '0.85em',
                                                        height: '42px',
                                                        width: '100%'
                                                    },
                                                    '& .MuiFormControl-root': {
                                                        width: '100%',
                                                        fontSize: '0.85em',
                                                        height: '42px'
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        fontSize: '0.85em',
                                                        height: '42px'
                                                    }
                                                }}
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                value={state?.startDate ? dayjs(new Date(state.startDate)) : null}
                                                onChange={(e) => {
                                                    if (moment(new Date(e)).isValid()) {
                                                        handleOnChanges('startDate', new Date(e));
                                                    }
                                                }}
                                                minDate={dayjs()}
                                            />
                                        </Box>
                                    </LocalizationProvider>
                                </Grid2>

                                <Grid2 size={{ xs: 12, md: 6 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box sx={{ width: '100%' }}>
                                            <DatePicker
                                                label="Travel End Date"
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiInputBase-root': {
                                                        fontSize: '0.85em',
                                                        height: '42px',
                                                        width: '100%'
                                                    },
                                                    '& .MuiFormControl-root': {
                                                        width: '100%',
                                                        fontSize: '0.85em',
                                                        height: '42px'
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        fontSize: '0.85em',
                                                        height: '42px'
                                                    }
                                                }}
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                value={state?.endDate ? dayjs(new Date(state.endDate)) : null}
                                                onChange={(e) => {
                                                    if (moment(new Date(e)).isValid()) {
                                                        handleOnChanges('endDate', new Date(e));
                                                    }
                                                }}
                                                disabled={!state?.startDate}
                                                minDate={dayjs(new Date(!state?.startDate))}
                                                {...(state?.startDate && {
                                                    minDate: dayjs(new Date(moment(new Date(state.startDate))))
                                                })}
                                                {...(state?.startDate && {
                                                    maxDate: dayjs(
                                                        new Date(
                                                            moment(new Date(state.startDate)).add(
                                                                DurationService(state, maintenance),
                                                                'days'
                                                            )
                                                        )
                                                    )
                                                })}
                                            />
                                        </Box>
                                    </LocalizationProvider>
                                </Grid2>
                            </Grid2>
                        </>
                    </Grid2>
                </>
            )}
            <Grid2 size={{ xs: 12 }}>
                <Grid2 container sx={{ flexGrow: 1 }}>
                    <>
                        <Grid2 container sx={{ flexGrow: 1 }}>
                            <Grid2 size={{ xs: 12, md: 6 }} offset={{ xs: 0, md: 6 }}>
                                <ButtonComponent
                                    {...((!!(
                                        !state.type ||
                                        !state.groupSize ||
                                        !state.plan ||
                                        !state.geoCoverage ||
                                        !state.transportMode ||
                                        !state.destination ||
                                        !companionsAllowed ||
                                        !(state.durationTypeCd
                                            ? true
                                            : state.plan === 'A' && state.geoCoverage === 'IWD'
                                            ? !!state.maxTripDuration
                                            : (!!state.startDate && validateDates()) ||
                                              (!!state.endDate && validateDates()) ||
                                              state.plan === 'A' ||
                                              false)
                                    ) && { color: 'rgba(0, 0, 0, 0.26) !important' }) ||
                                        {})}
                                    disabled={
                                        !!(
                                            !state.type ||
                                            !state.groupSize ||
                                            !state.plan ||
                                            !state.geoCoverage ||
                                            !state.transportMode ||
                                            !state.destination ||
                                            !companionsAllowed ||
                                            !(state.durationTypeCd
                                                ? true
                                                : state.plan === 'A' && state.geoCoverage === 'IWD'
                                                ? !!state.maxTripDuration
                                                : (!!state.startDate && validateDates()) ||
                                                  (!!state.endDate && validateDates()) ||
                                                  state.plan === 'A' ||
                                                  false)
                                        )
                                    }
                                    label="QUOTE"
                                    handleClick={() => {
                                        setState((p) => ({
                                            ...p,
                                            premAmount: QuotationService.premium(
                                                maintenance?.travelPremium || [],
                                                state
                                            )
                                        }));
                                        setTimeout(() => {
                                            const viewportHeight = window.innerHeight;
                                            window.scrollBy({
                                                top: viewportHeight,
                                                left: 0,
                                                behavior: 'smooth'
                                            });
                                        }, 100);
                                    }}
                                />
                            </Grid2>
                        </Grid2>
                    </>
                </Grid2>
            </Grid2>
            <TravellersModal open={openModal === 'travellers'} handleClose={() => setOpenModal('')} />
            <PlanModal open={openModal === 'plan'} handleClose={() => setOpenModal('')} />
        </Grid2>
    );
}

export default SelectionScaffold;
