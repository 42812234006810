import React from 'react';
import styled from 'styled-components';
import { Card, Grid2, Typography } from '@mui/material';

import { SelectionScaffold, Menu } from '../..';

import { AppContext } from '../../../services';

const StyledCard = styled(Card)`
    padding: 35px;
    border-radius: 16px !important;
`;

const StyledText = styled(Typography)`
    font-size: 12px !important;
    font-weight: 600 !important;
`;

const StyledMenuContainer = styled(Grid2)`
    margin-bottom: 20px;
`;

function DetailsScaffold() {
    const { state, setState, maintenance } = React.useContext(AppContext);

    function handleSelect(type) {
        setState(() => ({ type }));
    }

    return (
        <StyledCard elevation={1}>
            <Grid2 container direction="column" gap={1} columnSpacing={6}>
                <Grid2 size={{ xs: 12 }}>
                    <StyledText gutterBottom={false}>Choose Your Travel Type</StyledText>
                </Grid2>
                <StyledMenuContainer size={{ xs: 12, md: 8 }}>
                    <Menu
                        items={maintenance?.travelType?.map((travelType) => ({
                            label: travelType?.travelTypeDesc,
                            isActive: state?.type === travelType?.travelTypeCd,
                            handleSelect: () => {
                                handleSelect(travelType?.travelTypeCd);
                            }
                        }))}
                    />
                </StyledMenuContainer>
                <Grid2 size={{ xs: 12 }}>
                    <SelectionScaffold />
                </Grid2>
            </Grid2>
        </StyledCard>
    );
}

export default DetailsScaffold;
